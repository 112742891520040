button#my_icon_style {
    padding-top: 0rem;
    padding-bottom: 0rem;
    padding-left: 0.05rem;
    padding-right: 0.05rem;
}

.hdr-set {
    white-space: unset;
}
