div#title {
    text-align: center;
}

div#card {
    margin-left: 10%;
    margin-right: 10%;
}

div#top-card {
    margin-top: 30px;
    margin-left: 10%;
    margin-right: 10%;
}
