.modal2 {
}
.vertical-alignment-helper {
    display:table;
    height: 100%;
    width: 100%;
}
.vertical-align-center {
    /* To center vertically */
    display: table-cell;
    vertical-align: middle;
}
.modal-content {
    /* Bootstrap sets the size of the modal in the modal-dialog class, we need to inherit it */
    width:inherit;
    height:inherit;
    /* To center horizontally */
    margin: 0 auto;
}

.display0 {
    display: none;
}
.display1 {
    display: block;
}
.lbl-align {
    text-align: right;
}

.container {
    overflow-y: scroll;
    height: 400px;
}