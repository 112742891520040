.logo-container {
    margin: 1rem auto;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}
.img-margin {
    width: 150px;
    margin: 0.5em auto 0.3em
}
.tip-txt-align {
    text-align: center;
}

.download-progress {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 5px;
  background-color: #2ecc71;
  width: 0;
  transition: width 0.4s;
}

.button-container {
  position: relative;
}

.spinner {
  font-size: 30px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.toast-container {
  position: fixed !important; /* Fixed position */
  top: 50% !important; /* Center vertically */
  left: 50% !important; /* Center horizontally */
  transform: translate(-50%, -50%) !important; /* Adjust for exact center */
  z-index: 9999 !important; /* Ensure it's on top */
  min-width: 200px; /* Adjust as needed */
  text-align: center;
}
